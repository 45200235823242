/* 
      ---< Tooltip >---

  Generation of a Tooltip with Node information (Check Tier restrictions)

  - NodeId
  - AggregateBufferFillingRate
  - BufferHealth
  - Capacity
  - P2PConnectionStatus
  - NatType
  - IP
  - AsNname

*/
import { TierDataStructure, graphType } from '../data/fields';
import { globalFlags } from '../globalflags';

function bhProgressColor(value: number) {
  for (const index in globalFlags.bufferHealth) {
    if (value < globalFlags.bufferHealth[index]) {
      return `accent-color: ${globalFlags.bufferHealthColor[index]}`;
    }
  }
  return `accent-color: ${globalFlags.bufferHealthColor[globalFlags.bufferHealthColor.length - 1]}`;
}

const setNodeIDFieldValue = (CFIdValues: string[], CFSessionValues: string[], index: number) => {
  const shortIdInit = CFIdValues[index].slice(0, 6);
  const shortIdEnd = CFIdValues[index].slice(-6);
  return CFIdValues[index] === CFSessionValues[index] ? 'no-node-id' : `${shortIdInit}..${shortIdEnd}`;
};

function abfrProgressColor(value: number) {
  for (const index in globalFlags.aggregateBufferFillingRate) {
    if (value < globalFlags.aggregateBufferFillingRate[index]) {
      return `accent-color: ${globalFlags.aggregateBufferFillingRateColor[index]}`;
    }
  }
  return `accent-color: ${
    globalFlags.aggregateBufferFillingRateColor[globalFlags.aggregateBufferFillingRateColor.length - 1]
  }`;
}

function getProgressTagHtml(style_fn: (value: number) => string, value: number, max: number) {
  return `<progress style="${style_fn(value)}" value="${value}" max="${max}"> ${value} </progress>`;
}

function getConnectionStatusEmoji(value: string) {
  switch (value) {
    case 'UNKNOWN':
      return '&#x2753'; // Red Octogone
    case 'P2P_CONNECTING':
      return '&#x274E'; // Green X
    case 'P2P_CONNECTED':
      return '&#x2705'; // green V
    case 'NO_PARENT':
      return '&#x1F6D1'; // red X
  }
  return '';
}

function getNatTypeString(value: string) {
  switch (value) {
    case 'UNKNOWN':
      return '&#x2753'; // Red ?
    case 'NON_SYMMETRIC':
      return 'NON_SYMMETRIC';
    case 'SYMMETRIC':
      return 'SYMMETRIC';
    case 'SEQUENTIAL':
      return 'SEQUENTIAL';
  }
  return '';
}

function formatNumberLength(num: string | number, length = 0): string {
  if (!num.toString().includes('.')) {
    return num.toString();
  }

  const integer = num.toString().split('.')[0];
  const decimals = num.toString().split('.')[1];
  if (integer.length > length - 1) {
    return integer;
  }
  if (integer.length > length - 2) {
    return integer + '.' + decimals.slice(0, 1);
  }

  return integer + '.' + decimals.slice(0, 2);
}

function truncateString(inputString: string, maxSize: number): string {
  let tuncatedString = inputString ?? '';

  if (tuncatedString.length > maxSize) {
    tuncatedString = tuncatedString.substring(0, maxSize) + '...';
  }

  return tuncatedString;
}

export function generateTooltip(cleanedFields: TierDataStructure, index: number, graphType: graphType) {
  const isAdvanced = graphType === 'advance';
  let afbrInfo,
    advancedInfo = '';

  const shortSessionIdInit =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(0, 6) : '';
  const shortSessionIdEnd =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(-6) : '';

  // Add BufferHealth
  const bhProgressBar = getProgressTagHtml(
    bhProgressColor,
    cleanedFields.bufferHealthValues !== undefined ? parseInt(cleanedFields.bufferHealthValues[index], 10) : 0,
    globalFlags.progressBarMaxBufferHealth
  );
  const bhValue =
    cleanedFields.bufferHealthValues !== undefined ? formatNumberLength(cleanedFields.bufferHealthValues[index]) : '';

  const nodeID = setNodeIDFieldValue(cleanedFields.idValues, cleanedFields.sessionValues ?? [], index);

  if (isAdvanced) {
    // Add ABFR
    const abfrProgressBar = getProgressTagHtml(
      abfrProgressColor,
      cleanedFields.abfrValues !== undefined ? parseInt(cleanedFields.abfrValues[index], 10) : 0,
      globalFlags.progressBarMaxaggregateBufferFillingRate
    );
    const abfrValue =
      cleanedFields.abfrValues !== undefined
        ? formatNumberLength(parseInt(cleanedFields.abfrValues[index], 10) / 100, 4)
        : 0;

    // Add UpLinkCapacity
    const capacityString = cleanedFields.uplinkCapacity !== undefined ? cleanedFields.uplinkCapacity[index] : '';

    // ConnectionStatus Emoji
    const csEmoji =
      cleanedFields.connectionStatus !== undefined
        ? getConnectionStatusEmoji(cleanedFields.connectionStatus[index])
        : '';

    // Add NAT
    const natTypeString = cleanedFields.natType !== undefined ? getNatTypeString(cleanedFields.natType[index]) : '';

    // Add IP
    const ipString = cleanedFields.ip !== undefined ? cleanedFields.ip[index] : '';
    // Add ASN NAME
    const asnString =
      cleanedFields.asname !== undefined
        ? truncateString(cleanedFields.asname[index], globalFlags.maxSizeTooltipString)
        : '';

    afbrInfo = `<div>- <b>ABFR:</b> ${abfrProgressBar} ${abfrValue}</div>`;
    advancedInfo = `<div>- <b>Capacity:</b> ${capacityString}</div>
      <div>- <b>P2PConnectionStatus:</b> ${csEmoji}</div>
      <div>- <b>NatType:</b> ${natTypeString}</div>
      <div>- <b>IP:</b> ${ipString}</div>
      <div>- <b>ASN:</b> ${asnString}</div>`;
  }

  return `<div><b>SessionId:</b> ${shortSessionIdInit}..${shortSessionIdEnd}</div>
  <div>- <b>NodeId:</b> ${nodeID}</div>
  ${afbrInfo}
  <div>- <b>BH:</b> &#160;&#160;${bhProgressBar} ${bhValue} [s]</div>
  ${advancedInfo}`;
}
