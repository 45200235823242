import { useState, useEffect, useMemo } from 'react';
import { PanelData } from '@grafana/data';
import {
  validDataMask,
  getFieldDataByName,
  getCleanedFieldsByTier,
  GraphDataInterface,
  isFirstLevelWithCollapsedNodes,
  selectNodesFromRootNode,
  getLimitByLevel,
  restrictNodesByLevel,
  setGraphNodesLinks,
  cleanUpData,
} from '../nodeutils';
import { format, differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { globalFlags } from 'globalflags';
import { isBoolean } from 'lodash';

interface GraphDataOptions {
  rootNode: string;
  showNoCollapsedNodes: boolean;
  nodesThreshold: number;
}

const changeToTimeZone = (timestamp: number, timezone: string) => {
  const timestampDate = new Date(timestamp);
  if (timezone !== 'browser') {
    const timezoneTranform = timezone.toLowerCase() === 'utc' ? timezone.toUpperCase() : timezone;
    return formatInTimeZone(timestampDate, timezoneTranform, 'yyyy-MM-dd HH:mm:ss');
  }

  return format(timestampDate, 'yyyy-MM-dd HH:mm:ss');
};

const useGraphData = (initialData: PanelData, options: GraphDataOptions) => {
  const { rootNode, showNoCollapsedNodes, nodesThreshold } = options;
  const [graphData, setGraphData] = useState<any>(null);
  const [nodeFilterControl, setNodeFilterControl] = useState<boolean>(true);

  // Check the graph type either advance or lite
  const advanceTier = useMemo(() => {
    const dataFields = initialData?.series[0]?.fields || [];
    return dataFields.length > 6;
  }, [initialData.series]);

  // Treat Grafana data and get the unique NodeId and all the other fields
  const graphTotalInfo = useMemo(() => {
    const dataFields = initialData?.series[0]?.fields || [];
    const isAdvancedTier = isBoolean(advanceTier) ? advanceTier : dataFields.length > 6;
    const cleanDataMask = validDataMask(getFieldDataByName({ json: dataFields, fieldName: 'NodeId' }));
    return getCleanedFieldsByTier(isAdvancedTier ? 'advance' : 'lite', dataFields, cleanDataMask);
  }, [initialData.series, advanceTier]);

  // Work out the max timestamp from Grafana's data and set it up with the dashboard timezone
  const graphDateInfo = useMemo(() => {
    let graphDate = '';
    const dataFields = initialData?.series[0]?.fields || [];
    const cleanDataMask = validDataMask(getFieldDataByName({ json: dataFields, fieldName: 'NodeId' }));
    let timestampRows: string[] | number[] = cleanUpData(
      getFieldDataByName({ json: dataFields, fieldName: 'timestamp' }),
      cleanDataMask
    );

    if (timestampRows.length) {
      timestampRows = timestampRows.map(Number);
      const maxTimestamp = Math.max(...timestampRows);
      graphDate = changeToTimeZone(maxTimestamp, initialData.request?.timezone || 'browser');
    }

    return graphDate;
  }, [initialData.series, initialData.request]);

  // Work out if time range filter is within the last 30 days
  const monthTimeRange = useMemo(() => {
    // calc if grafana filter range is either around last 30 days or not
    const timeRangeFrom = initialData?.timeRange?.from;
    const timeRangeTo = initialData?.timeRange?.to;
    let timeRangeWithin = false;

    if (typeof timeRangeFrom.toDate === 'function' && typeof timeRangeTo.toDate === 'function') {
      const diffTimeRangeFrom = differenceInCalendarDays(new Date(timeRangeFrom.toDate()), new Date());
      const diffTimeRangeTo = differenceInCalendarDays(new Date(timeRangeTo.toDate()), new Date());
      if (diffTimeRangeTo < 0) {
        timeRangeWithin = diffTimeRangeTo >= -30 && diffTimeRangeFrom - diffTimeRangeTo >= -30;
      } else {
        timeRangeWithin = diffTimeRangeFrom >= -30;
      }
    }

    return timeRangeWithin;
  }, [initialData.timeRange]);

  useEffect(() => {
    // After treated Grafana's data to get general info:
    const processGraphData = () => {
      // select all nodes from a root node specified, either CDN or another one
      let graphFromNode = selectNodesFromRootNode(graphTotalInfo, rootNode, showNoCollapsedNodes, nodesThreshold);
      const showNodeFilterControl = rootNode === globalFlags.poiId && isFirstLevelWithCollapsedNodes(graphTotalInfo);
      setNodeFilterControl(showNodeFilterControl);
      let maxTreeLevel = -1;
      // With the nodes selected, if their length exceeds the limit configured
      if (graphFromNode.idValues.length > nodesThreshold) {
        // Determine the max level to render according to the limit
        maxTreeLevel = getLimitByLevel(graphFromNode?.levelValues ?? [], nodesThreshold);
        // build a new collection of nodes based on the restriction by level
        graphFromNode = restrictNodesByLevel(graphFromNode, maxTreeLevel);
      }
      // With the final collection of nodes selected, it builds the links, nodes and add the CDN if applies
      const gData: GraphDataInterface = setGraphNodesLinks(
        graphFromNode,
        advanceTier ? 'advance' : 'lite',
        maxTreeLevel,
        rootNode
      );
      setGraphData(gData);
    };

    processGraphData();
  }, [graphTotalInfo, rootNode, advanceTier, showNoCollapsedNodes, nodesThreshold]);

  return [advanceTier, graphTotalInfo, graphData, graphDateInfo, monthTimeRange, nodeFilterControl];
};

export default useGraphData;
