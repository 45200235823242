import React, { useEffect, useRef, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions, InfoBoxItem } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import ForceGraph3d from 'react-force-graph-3d';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

//====== custom plugin
import useGraphData from 'hooks/useGraphData';
import { MessageModal, MessageModalControls } from './MessageModal';
import { InfoBox } from './InfoBox';
import GUI from 'lil-gui';
import { getLayout, getNodesThresholdTitle } from 'interface/gui-extra';
import { globalFlags } from 'globalflags';
import { NodeColorSelector, nodeColorSelectorFactory, PolynetNodeObject3D } from 'nodeColorStrategies';
import { getNodeNavigationInfo, collapsedNodeLabelVisible, formatNumberToString } from 'nodeutils';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import 'style.css';
import { cloneDeep } from 'lodash';
//====

interface Props extends PanelProps<SimpleOptions> {}

const NO_DATA_WITHIN_30_DAYS = 'There is no data to display for the selected end of time interval.';
const NO_DATA_OUT_OF_RANGE = 'Please select an interval ending within the last 30 days for this chart.';
const NODES_THRESHOLD_WARNING = "Incrementing the suggested nodes threshold might affect the panel's performance.";
const NODES_THRESHOLD_CONFIRM =
  "Incrementing the suggested nodes threshold might affect the panel's performance. Do you want to continue?";

const controls = {
  Metric: 'BufferHealth',
  Layout: 'Top-Down',
  GhostNode: false,
}; // Default values

let highlightNodeId: string | undefined;

export const SimplePanel: React.FC<Props> = ({ width, height, data }) => {
  const topologyRef = useRef<any>();
  const styles = useStyles2(getStyles);
  library.add(faArrowLeft, faWarning);
  const returnIcon = icon({ prefix: 'fas', iconName: 'arrow-left' });
  const warningIcon = icon({ prefix: 'fas', iconName: 'triangle-exclamation' });
  const nodeColorSelector = new NodeColorSelector(nodeColorSelectorFactory(controls.Metric));
  const extraRenderers: any[] = [new CSS2DRenderer()];
  const [nodesGoBack, setNodesGoBack] = useState<string[]>([]);
  const [rootNode, setRootNode] = useState(globalFlags.poiId);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [infoBoxData, setInfoBoxData] = useState<InfoBoxItem[]>([]);
  const [layoutState, setLayoutState] = useState({ layout: 'Top-Down' });
  const [nodeFilterState, setNodeFilterState] = useState({ nodeFilter: true });
  const [metricState, setMetricState] = useState({ metric: 'BufferHealth' });
  const [nodesThresholdState, setNodesThresholdState] = useState({ nodesThreshold: globalFlags.maxNumberTreeNodes });
  const [nodesThresholdConfirmationPopup, setNodesThresholdConfirmationPopup] = useState(false);
  const [nodesThresholdConfirmationFlag, setNodesThresholdConfirmationFlag] = useState(false);
  const [nodesThresholdConfirmationValue, setNodesThresholdConfirmationValue] = useState(
    globalFlags.maxNumberTreeNodes
  );

  const [advanceTier, graphTotalInfo, graphData, graphDateInfo, monthTimeRange, nodeFilterControl] = useGraphData(
    data,
    {
      rootNode,
      showNoCollapsedNodes: !nodeFilterState.nodeFilter,
      nodesThreshold: nodesThresholdState.nodesThreshold,
    }
  );

  const nodeColorCallback = (node: any) => {
    nodeColorSelector.setColorStrategy(nodeColorSelectorFactory(metricState.metric));
    return nodeColorSelector.colorFunction(node as PolynetNodeObject3D, highlightNodeId);
  };

  const nodeDragEndCallback = (node: any) => {
    const myNode = node as PolynetNodeObject3D;
    myNode.fx = myNode.x;
    myNode.fy = myNode.y;
    myNode.fz = myNode.z;
  };

  const onEngineStopCallback = () => {
    if (globalFlags.firstTimeZoom) {
      topologyRef.current.zoomToFit(globalFlags.zoomToFitDelayMs);
      globalFlags.firstTimeZoom = false;
    }
  };

  const handleNodeClick = (node: any) => {
    const myNode = node as PolynetNodeObject3D;

    if (myNode.id !== '' && myNode.id !== globalFlags.poiId) {
      if (myNode.type === 'root' && nodesGoBack.length > 0) {
        const goToNode = nodesGoBack.slice(-1)[0];
        const newNodesGoBack = nodesGoBack.filter((node) => node !== goToNode);
        setNodesGoBack([...newNodesGoBack]);
        setRootNode(goToNode);
        globalFlags.firstTimeZoom = true;
      } else if (myNode?.children > 0) {
        setNodesGoBack([...nodesGoBack, rootNode]);
        setRootNode(myNode.id?.toString() ?? '');
        globalFlags.firstTimeZoom = true;
      }
    }
  };

  const handleNodesThresholdConfirm = () => {
    setNodesThresholdConfirmationPopup(false);
    setNodesThresholdState({ nodesThreshold: nodesThresholdConfirmationValue });
    globalFlags.firstTimeZoom = true;
  };

  const handleNodesThresholdCancel = () => {
    setNodesThresholdConfirmationFlag(false);
    setNodesThresholdConfirmationPopup(false);
    setNodesThresholdState({ nodesThreshold: globalFlags.maxNumberTreeNodes });
  };

  useEffect(() => {
    setNodesGoBack([]);
    setRootNode(globalFlags.poiId);
  }, [data.series]);

  useEffect(() => {
    const topologyRefCopy = topologyRef.current;

    topologyRefCopy.controls().addEventListener('end', () => collapsedNodeLabelVisible(graphData, topologyRefCopy));

    return () =>
      topologyRefCopy
        .controls()
        .removeEventListener('end', () => collapsedNodeLabelVisible(graphData, topologyRefCopy));
  }, [graphData]);

  useEffect(() => {
    const params = { autoPlace: false };
    const gui = new GUI(params);
    gui.domElement.id = 'gui';

    if (advanceTier) {
      const menuOptions = ['ABFR', 'BufferHealth', 'NatType'];
      gui
        .add(metricState, 'metric', menuOptions) // Metrics menu
        .onChange((metric: string) => {
          setMetricState({ metric });
        });
    }

    gui
      .add(layoutState, 'layout', ['Top-Down', 'Globular']) // Layout menu
      .onChange((layout: string) => setLayoutState({ layout }));

    gui
      .add(nodeFilterState, 'nodeFilter') // Filter Sigle Nodes Checkbox
      .name('Only P2P Connected Nodes   ')
      .onChange((check: boolean) => {
        setNodeFilterState({ nodeFilter: check });
        globalFlags.firstTimeZoom = true;
      })
      .show(nodeFilterControl);

    const nodesThresholdLimit =
      graphTotalInfo.idValues.length > globalFlags.maxNumberTreeNodes ? graphTotalInfo.idValues.length : 0;

    const nodesThresholdWarning = nodesThresholdState.nodesThreshold > globalFlags.maxNumberTreeNodes;

    const thresholdControllerValue = cloneDeep(nodesThresholdState);

    const thresholdController = gui
      .add(thresholdControllerValue, 'nodesThreshold', 1, nodesThresholdLimit, 10)
      .name(`${getNodesThresholdTitle('Nodes Threshold', warningIcon.html[0], nodesThresholdWarning)}   `)
      .onFinishChange((threshold: number) => {
        if (!nodesThresholdConfirmationFlag && threshold > globalFlags.maxNumberTreeNodes) {
          setNodesThresholdConfirmationFlag(true);
          setNodesThresholdConfirmationPopup(true);
          setNodesThresholdConfirmationValue(threshold);
        } else {
          setNodesThresholdState({ nodesThreshold: threshold });
          setNodesThresholdConfirmationPopup(false);
          globalFlags.firstTimeZoom = true;
        }
      })
      .show(nodesThresholdLimit !== 0);

    if (nodesThresholdWarning) {
      thresholdController.$name.classList.add('warning-tooltip');
      thresholdController.$widget.classList.add('warning-widget');
    } else {
      thresholdController.$name.classList.remove('warning-tooltip');
      thresholdController.$widget.classList.remove('warning-widget');
    }

    const htmlMenu = document.querySelector('#gui');
    if (htmlMenu) {
      document.getElementById('3d-graph')?.removeChild(htmlMenu);
    }

    if (graphData?.nodes?.length > 1) {
      document.getElementById('3d-graph')?.appendChild(gui.domElement);
    }
  }, [
    advanceTier,
    graphTotalInfo,
    graphData,
    layoutState,
    metricState,
    nodeFilterState,
    nodeFilterControl,
    nodesThresholdState,
    warningIcon.html,
    nodesThresholdConfirmationFlag,
  ]);

  useEffect(() => {
    if (graphData?.nodes?.length <= 1) {
      setNoDataFlag(true);
      if (monthTimeRange === true) {
        setNoDataMessage(NO_DATA_WITHIN_30_DAYS);
      } else {
        setNoDataMessage(NO_DATA_OUT_OF_RANGE);
      }
      setInfoBoxData([]);
    } else {
      setNoDataFlag(false);
      setNoDataMessage('');
      if (graphDateInfo !== '') {
        setInfoBoxData([
          {
            label: 'Date:',
            value: graphDateInfo,
            showLabel: false,
          },
          {
            label: '# Nodes:',
            value: graphTotalInfo.idValues.length,
            showLabel: true,
          },
        ]);
      }
    }
  }, [graphData, graphTotalInfo, monthTimeRange, graphDateInfo]);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div id="3d-graph">
        <ForceGraph3d
          ref={topologyRef}
          extraRenderers={extraRenderers}
          graphData={graphData ?? { nodes: [], links: [] }}
          nodeVal={(node: any) => (node as PolynetNodeObject3D).size}
          linkWidth={globalFlags.linkWidth}
          height={height}
          width={width}
          nodeId="id"
          nodeLabel="tooltip"
          //@ts-ignore
          dagMode={getLayout(layoutState['layout'])}
          d3VelocityDecay={globalFlags.velocityDecay}
          cooldownTime={globalFlags.cooldownTimeMs}
          onEngineStop={onEngineStopCallback}
          showNavInfo={false}
          linkColor={() => globalFlags.linkColor}
          nodeColor={nodeColorCallback}
          onNodeClick={handleNodeClick}
          onNodeDragEnd={nodeDragEndCallback}
          nodeThreeObject={(node: any) => {
            let nodeLabel = new CSS2DObject(document.createElement('div'));
            let nodeText = '';

            if (node.type === 'root' && node.id !== globalFlags.poiId) {
              nodeLabel = new CSS2DObject(getNodeNavigationInfo(nodeText, 'node-label-root', returnIcon.html[0]));
              nodeLabel.position.set(-5, 0, 0);
            } else if (node.collapsed !== undefined && node.children !== undefined) {
              if (node.collapsed === true && node.children > 0) {
                nodeText = `+${formatNumberToString(node.children)}`;
                const nodeLabelEl = getNodeNavigationInfo(nodeText, 'node-label', '');
                nodeLabelEl.setAttribute('id', `node-label-${node.id}`);
                nodeLabel = new CSS2DObject(nodeLabelEl);
                nodeLabel.position.set(0, -5, 0);
              }
            }

            return nodeLabel;
          }}
          nodeThreeObjectExtend={true}
          enableNavigationControls={true}
        />
      </div>
      <Tooltip anchorSelect=".warning-tooltip" content={NODES_THRESHOLD_WARNING} />
      <InfoBox items={infoBoxData} containerWidth={width} />
      <MessageModal show={noDataFlag} message={noDataMessage} />
      <MessageModal show={nodesThresholdConfirmationPopup} message={NODES_THRESHOLD_CONFIRM}>
        <MessageModalControls
          confirm={true}
          cancel={true}
          confirmAction={handleNodesThresholdConfirm}
          cancelAction={handleNodesThresholdCancel}
        />
      </MessageModal>
    </div>
  );
};

const getStyles = () => ({
  wrapper: css`
    font-family: Open Sans;
    position: relative;
  `,
  svg: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
  textBox: css`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
  `,
});
