/*
      ---< Node Utilities >---

 - PolynetNodeObject: nodes interface
 - getNodeLevel: get the node level from CDN
 - validDataMask: generates a mask with valid nodes (non-repeating)
 - cleanUpData: apply the mask over an array
 - checkLinks: check the links and find the broken ones. Set a ghost node for broken links.

 */
import { globalFlags } from './globalflags';
import { TierDataStructure, advanceTierDataStructure, liteTierDataStructure, graphType } from './data/fields';
import { generateTooltip } from 'interface/tooltip';
import { cloneDeep } from 'lodash';
import * as THREE from 'three';

export interface PolynetNodeObject {
  id?: string | number;
  x?: number;
  y?: number;
  z?: number;
  vx?: number;
  vy?: number;
  vz?: number;
  fx?: number;
  fy?: number;
  fz?: number;
  children: number;
  collapsed: boolean;

  size: number;
  level: number;

  type: string;
}

export interface GraphDataInterface {
  nodes: any[];
  links: any[];
}

export interface LevelsChildrenInterface {
  levels: string[];
  children: string[];
}

export const cleanedFieldGhostData = [
  {
    nodeName: 'idValues',
    nodeValue: globalFlags.ghostId,
  },
  {
    nodeName: 'sourceValues',
    nodeValue: globalFlags.poiId,
  },
  {
    nodeName: 'abfrValues',
    nodeValue: '0',
  },
  {
    nodeName: 'bufferHealthValues',
    nodeValue: '0',
  },
  {
    nodeName: 'connectionStatus',
    nodeValue: '0',
  },
  {
    nodeName: 'natType',
    nodeValue: '0',
  },
  {
    nodeName: 'uplinkCapacity',
    nodeValue: '0',
  },
  {
    nodeName: 'ip',
    nodeValue: '0',
  },
  {
    nodeName: 'asn',
    nodeValue: '0',
  },
  {
    nodeName: 'asname',
    nodeValue: '0',
  },
  {
    nodeName: 'sessionValues',
    nodeValue: globalFlags.ghostId,
  },
];

let childrenIds: string[] = [];
let childrenIdsTimes = 0;

// Split huge arrays into chunks
export function chunckArray(arr: any[], size: number) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
}

// Extract data field by name from Grafana data structure
export function getFieldDataByName({ json, fieldName }: { json: any; fieldName: string }) {
  return json.reduce((newValuesArr: any, currentField: any) => {
    if (currentField.name === fieldName) {
      let currentFieldValues;
      if (!Array.isArray(currentField.values)) {
        const objToArr: any[] = Object.values(currentField.values);
        currentFieldValues = objToArr.length && objToArr[0].length === currentField.values.length ? objToArr[0] : [];
      } else {
        currentFieldValues = currentField.values;
      }
      currentFieldValues = chunckArray(currentFieldValues, 50000);
      currentFieldValues.forEach((valuesChunk) => {
        newValuesArr.push(...valuesChunk);
      });
    }
    return newValuesArr;
  }, []);
}

// generate clean fields object for advance and lite tier
export function getCleanedFieldsByTier(tier: graphType, data: any[], cleanDataMask: any[]) {
  let cleanedFields: TierDataStructure = { idValues: [], sourceValues: [] };
  let tierDataStructure = tier === 'advance' ? advanceTierDataStructure : liteTierDataStructure;

  if (tierDataStructure) {
    tierDataStructure.forEach((tierField) => {
      cleanedFields[tierField?.nodeName as keyof typeof cleanedFields] = cleanUpData(
        getFieldDataByName({ json: data, fieldName: tierField?.fieldName }),
        cleanDataMask
      );
    });
  }

  cleanedFields.sourceValues = checkLinks(cleanedFields?.idValues, cleanedFields?.sourceValues);
  //add ghost data to cleaned fields
  cleanedFields = addGhostNodeToCleanedFields(cleanedFields);
  const levelsAndChildrenNodes = getLevelsChildrenNodes(cleanedFields?.idValues, cleanedFields?.sourceValues);
  cleanedFields.levelValues = levelsAndChildrenNodes.levels;
  cleanedFields.childrenLengthValues = levelsAndChildrenNodes.children;

  return cleanedFields;
}

function addGhostNodeToCleanedFields(cleanedFields: TierDataStructure) {
  if (cleanedFields.sourceValues.includes(globalFlags.ghostId)) {
    cleanedFieldGhostData.forEach((ghostData) => {
      cleanedFields[ghostData?.nodeName as keyof typeof cleanedFields]?.push(ghostData.nodeValue);
    });
  }
  return cleanedFields;
}

// generate final nodes and links for the graph
export function setGraphNodesLinks(
  cleanedFields: TierDataStructure,
  graphType: graphType,
  maxLevelTree: number,
  rootNode: string
) {
  const gData: GraphDataInterface = { nodes: [], links: [] };

  gData.links = setLinksFromSource(cleanedFields, rootNode);
  gData.nodes = setNodesFromIdValues(cleanedFields, graphType, maxLevelTree, rootNode);

  if (gData.nodes.length > 0 && rootNode === globalFlags.poiId) {
    const cdnNode = setCDNNode(graphType);
    gData.nodes = [...gData.nodes, ...cdnNode];
  }

  return gData;
}

function setLinksFromSource(cleanedFields: TierDataStructure, rootNode: string) {
  let links = cleanedFields.sourceValues.map((el, i) => ({
    source: el,
    target: cleanedFields.idValues[i],
  }));

  // remove link for NodeId to transform it as rootNode
  if (rootNode !== globalFlags.poiId) {
    links = links.filter((node) => node.target !== rootNode);
  }

  return links;
}

function setNodesFromIdValues(
  cleanedFields: TierDataStructure,
  graphType: graphType,
  maxLevelTree: number,
  rootNode: string
) {
  const nodes = cleanedFields.idValues.map((el, i) => {
    const childrenIds = cleanedFields.childrenLengthValues !== undefined ? cleanedFields.childrenLengthValues[i] : '';
    const nodeLevel = cleanedFields.levelValues !== undefined ? parseInt(cleanedFields.levelValues[i], 10) : 0;
    let node = {
      id: el,
      bh: cleanedFields.bufferHealthValues !== undefined ? cleanedFields.bufferHealthValues[i] : '',
      session: cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[i] : '',
      size: globalFlags.nodeSize,
      level: nodeLevel,
      children: childrenIds.trim() !== '' ? childrenIds.split(',').length : 0,
      collapsed: nodeLevel === maxLevelTree && childrenIds.trim() !== '',
      type: el === rootNode ? 'root' : 'node',
      tooltip:
        el === globalFlags.ghostId
          ? "<p align='center'><b>Ghost Node</b></p> "
          : generateTooltip(cleanedFields, i, graphType),
    };

    if (graphType === 'advance') {
      const advanceNodeData = {
        abfr: cleanedFields.abfrValues !== undefined ? cleanedFields.abfrValues[i] : '',
        nat: cleanedFields.natType !== undefined ? cleanedFields.natType[i] : '',
      };

      node = { ...node, ...advanceNodeData };
    }

    return node;
  });

  return nodes;
}

function setCDNNode(graphType: graphType) {
  let cdnNode = [];
  let cdnNodeData = {
    id: globalFlags.poiId,
    bh: '0',
    session: globalFlags.poiId,
    size: globalFlags.poiNodeSize,
    type: 'root',
    tooltip: "<p align='center'><b>CDN</b></p> ",
  };

  if (graphType === 'advance') {
    cdnNodeData = { ...cdnNodeData, ...{ abfr: '0', nat: '0' } };
  }

  cdnNode.push(cdnNodeData);

  return cdnNode;
}

// Generates a boolean Mask of non duplicated values
export function validDataMask(idValues: string[] | undefined) {
  const mask: boolean[] = [];
  const checkedValues: string[] = [];

  if (idValues) {
    idValues.forEach((value) => {
      if (checkedValues.includes(value)) {
        mask.push(false);
        return;
      }
      mask.push(true);
      checkedValues.push(value);
    });
  }

  return mask;
}

// Applies a Mask over an Array
export function cleanUpData(dataVector: string[] | undefined, mask: boolean[]) {
  return dataVector?.filter((_, index) => mask[index]) || [];
}

// Check if the Sources exist in the system and generate the Ghost Node and the mask indicating its position
export function checkLinks(idNodes: string[], idSources: string[]): string[] {
  return idSources.map((node: string) => {
    if (idNodes.includes(node) || node === globalFlags.poiId) {
      return node;
    } else {
      return globalFlags.useGhostNode ? globalFlags.ghostId : globalFlags.poiId;
    }
  });
}

export function getNodeLevel(nodeId: string, targetSourceMap: Map<string, string>) {
  let currentLevel = globalFlags.poiLevel;
  let currentNodeId = nodeId;
  let findLevel = true;

  while (findLevel) {
    const parent = targetSourceMap.get(currentNodeId);

    if (!parent || parent === globalFlags.poiId) {
      findLevel = false;
    } else {
      currentLevel++;
      currentNodeId = parent;
    }

    if (currentLevel > globalFlags.maxNumberTreeLevels) {
      findLevel = false;
    }
  }

  return currentLevel.toString();
}

export function getChildrenIds(nodeId: string, targetSources: any[]) {
  if (childrenIdsTimes >= targetSources.length) {
    return;
  }
  childrenIdsTimes++;
  const hasChildren = targetSources.find((targetSource) => targetSource.source === nodeId);

  if (hasChildren) {
    let directChildrenNodes = targetSources
      .filter((targetSource) => targetSource.source === nodeId)
      .map((targetSourceFiltered) => targetSourceFiltered.target);
    childrenIds = [...childrenIds, ...directChildrenNodes];
    directChildrenNodes.map((childNode) => getChildrenIds(childNode, targetSources));
  }

  childrenIds = Array.from(new Set(childrenIds));
}

export function getLevelsChildrenNodes(idNodes: string[], idSources: string[]) {
  const levelsChildrenData: LevelsChildrenInterface = { levels: [], children: [] };
  const targetSources = Array.from({ length: idNodes.length }, (_, i) => ({
    source: idSources[i],
    target: idNodes[i],
  }));
  const targetSourcesMap = new Map(targetSources.map(({ source, target }) => [target, source]));
  if (idSources.includes(globalFlags.ghostId) && !idNodes.includes(globalFlags.ghostId)) {
    targetSourcesMap.set(globalFlags.ghostId, globalFlags.poiId);
  }

  idNodes.forEach((node) => {
    levelsChildrenData.levels.push(getNodeLevel(node, targetSourcesMap));
    childrenIds = [];
    childrenIdsTimes = 0;
    getChildrenIds(node, targetSources);
    levelsChildrenData.children.push(childrenIds.toString());
  });

  return levelsChildrenData;
}

export function selectNodesFromRootNode(
  cleanedFields: TierDataStructure,
  rootNode: string,
  showNoCollapsedNodes: boolean,
  nodesThreshold: number
) {
  let cleanedFieldsClone = cloneDeep(cleanedFields);

  if (rootNode !== globalFlags.poiId) {
    const indexesMask: number[] = [];
    const rootIndex = cleanedFieldsClone.idValues.findIndex((target) => target === rootNode);

    if (rootIndex !== -1 && rootIndex in (cleanedFields?.childrenLengthValues ?? [])) {
      indexesMask.push(rootIndex);
      const childrenIds = (cleanedFields?.childrenLengthValues ?? [])[rootIndex].split(',');

      childrenIds?.forEach((childId, index) => {
        const childIndex = cleanedFieldsClone.idValues.findIndex((target) => target === childId);
        if (childIndex !== -1) {
          indexesMask.push(childIndex);
        }
      });

      if (indexesMask.length) {
        const cleanedFieldsCloneKeys = Object.keys(cleanedFieldsClone);
        cleanedFieldsCloneKeys.forEach((field) => {
          cleanedFieldsClone[field as keyof typeof cleanedFields] =
            cleanedFields[field as keyof typeof cleanedFields]?.filter((val, index) => indexesMask.includes(index)) ??
            [];
        });
      }
    }
  } else if (!showNoCollapsedNodes) {
    const cleanedFieldsCloneKeys = Object.keys(cleanedFieldsClone);
    const firstLevelWithCollapsedNodes = isFirstLevelWithCollapsedNodes(cleanedFieldsClone);

    if (!firstLevelWithCollapsedNodes && cleanedFieldsClone.idValues.length > nodesThreshold) {
      cleanedFieldsCloneKeys.forEach((field) => {
        cleanedFieldsClone[field as keyof typeof cleanedFields] =
          cleanedFields[field as keyof typeof cleanedFields]?.filter((val, index) => index < nodesThreshold) ?? [];
      });
    } else if (firstLevelWithCollapsedNodes) {
      const indexesMask: number[] = [];

      cleanedFieldsClone.idValues.forEach((nodeId, index) => {
        if (index in (cleanedFields?.levelValues ?? []) && index in (cleanedFields?.childrenLengthValues ?? [])) {
          const nodeLevel = (cleanedFields?.levelValues ?? [])[index];
          const nodeChildren = (cleanedFields?.childrenLengthValues ?? [])[index];
          if (nodeLevel !== '1' || (nodeLevel === '1' && nodeChildren !== '')) {
            indexesMask.push(index);
          }
        }
      });

      if (indexesMask.length) {
        cleanedFieldsCloneKeys.forEach((field) => {
          cleanedFieldsClone[field as keyof typeof cleanedFields] =
            cleanedFields[field as keyof typeof cleanedFields]?.filter((val, index) => indexesMask.includes(index)) ??
            [];
        });
      }
    }
  }

  return cleanedFieldsClone;
}

export function isFirstLevelWithCollapsedNodes(cleanedFields: TierDataStructure) {
  const cleanedFieldsClone = cloneDeep(cleanedFields);
  let response = false;

  if (cleanedFieldsClone.levelValues !== undefined) {
    response = cleanedFieldsClone.levelValues?.some((level) => {
      return parseInt(level, 10) > 1;
    });
  }

  return response;
}

export function getLimitByLevel(levelsArray: string[], nodesThreshold: number) {
  const levels = levelsArray.map(Number).sort((a, b) => a - b);
  const uniqueLevels = [...new Set(levels)];
  const levelsCount = uniqueLevels.map((uniqueValue) => [
    uniqueValue,
    levels.filter((levelValue) => levelValue === uniqueValue).length,
  ]);
  let maxLevel = 0;
  let sumNodes = 0;
  let prevSumNodes = 0;

  for (let levelInfo of levelsCount) {
    prevSumNodes = sumNodes;
    sumNodes = +levelInfo[1] + sumNodes;

    if (prevSumNodes > 1 && sumNodes > nodesThreshold && maxLevel > 0) {
      break;
    }

    maxLevel = parseInt(levelInfo[0].toString(), 10);
  }

  return maxLevel;
}

export function restrictNodesByLevel(cleanedFields: TierDataStructure, maxTreeLevel: number) {
  if (maxTreeLevel <= 0) {
    return cleanedFields;
  }

  let cleanedFieldsCloned = cloneDeep(cleanedFields);
  const cleanedFieldsClonedKeys = Object.keys(cleanedFields);
  const indexesMask: number[] = [];

  cleanedFields.levelValues?.forEach((level, index) => {
    if (parseInt(level, 10) <= maxTreeLevel) {
      indexesMask.push(index);
    }
  });

  cleanedFieldsClonedKeys.forEach((field) => {
    cleanedFieldsCloned[field as keyof typeof cleanedFields] =
      cleanedFields[field as keyof typeof cleanedFields]?.filter((val, index) => indexesMask.includes(index)) ?? [];
  });

  return cleanedFieldsCloned;
}

export function formatNumberToString(n: number) {
  let formatted = '';
  switch (true) {
    case n >= 1e12:
      formatted = +(n / 1e12).toFixed(1) + 'T';
      break;
    case n >= 1e9:
      formatted = +(n / 1e9).toFixed(1) + 'B';
      break;
    case n >= 1e6:
      formatted = +(n / 1e6).toFixed(1) + 'M';
      break;
    case n >= 1e3:
      formatted = +(n / 1e3).toFixed(1) + 'K';
      break;
    default:
      formatted = n.toString();
      break;
  }

  return formatted;
}

export function getNodeNavigationInfo(navigationInfo: string, className: string, icon = '') {
  const nodeEl = document.createElement('div');
  const nodeElIcon = document.createElement('div');
  const nodeElValue = document.createElement('div');

  nodeElValue.textContent = `${navigationInfo}`;

  if (icon !== '') {
    nodeElIcon.innerHTML = icon;
    nodeEl.appendChild(nodeElIcon);
  }

  nodeEl.appendChild(nodeElValue);
  nodeEl.className = className;
  return nodeEl;
}

export function graphDepth(topologyGraphRef: any) {
  const vector = new THREE.Vector3();
  const graphDirection = topologyGraphRef.camera().getWorldDirection(vector).negate();
  const graphDimension = topologyGraphRef.getGraphBbox();
  let graphDepth = 0;

  if (graphDimension !== undefined) {
    if (Math.abs(graphDirection.x) > 0.89 && graphDimension.x !== undefined) {
      graphDepth = graphDimension.x[0] - graphDimension.x[1];
    } else if (Math.abs(graphDirection.y) > 0.89 && graphDimension.y !== undefined) {
      graphDepth = graphDimension.y[0] - graphDimension.y[1];
    } else if (graphDimension.z !== undefined) {
      graphDepth = graphDimension.z[0] - graphDimension.z[1];
    }
  }

  return Math.abs(graphDepth) > 500 ? Math.abs(graphDepth) : 500;
}

export function distanceBetweenTwoPoints(originPosition: any, endPosition: any) {
  const dx = originPosition.x - endPosition.x;
  const dy = originPosition.y - endPosition.y;
  const dz = originPosition.z - endPosition.z;

  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

export function distanceFromNearPlane(cameraPosition: any, nodePosition: any, topologyGraphRef: any) {
  const nearPlane = { ...nodePosition };
  const nodeVector = { ...nodePosition };
  let direction = 'z';
  if (topologyGraphRef !== null) {
    const vector = new THREE.Vector3();
    const graphDirection = topologyGraphRef.camera().getWorldDirection(vector).negate();
    if (Math.abs(graphDirection.x) > 0.89) {
      direction = 'x';
    } else if (Math.abs(graphDirection.y) > 0.89) {
      direction = 'y';
    }
  }

  switch (direction) {
    case 'x':
      nearPlane.x = cameraPosition.x;
      break;
    case 'y':
      nearPlane.y = cameraPosition.y;
      break;
    case 'z':
      nearPlane.z = cameraPosition.z;
      break;
  }

  return distanceBetweenTwoPoints(nearPlane, nodeVector);
}

export function collapsedNodeLabelVisible(graphData: any, topologyGraphRef: any) {
  if (graphData !== null) {
    const depth = graphDepth(topologyGraphRef);

    graphData.nodes
      .filter((node: any) => node.collapsed === true && node.children > 0)
      .map((node: any) => {
        const nodeLabelEl = document.getElementById(`node-label-${node.id}`);
        if (nodeLabelEl && node?.__threeObj !== undefined) {
          const cameraPosition = topologyGraphRef.camera().position.clone();
          const nodePosition = node.__threeObj.position.clone();
          const distance = distanceBetweenTwoPoints(cameraPosition, nodePosition);
          const distanceNearPlane = distanceFromNearPlane(cameraPosition, nodePosition, topologyGraphRef);

          if (distance <= depth || distanceNearPlane <= depth) {
            nodeLabelEl.classList.add('visible');
          } else {
            nodeLabelEl.classList.remove('visible');
          }
        }
      });
  }
}
