import React from 'react';
import { MessageModalProps, MessageModalControlsProps } from 'types';

export const MessageModalControls: React.FC<MessageModalControlsProps> = ({
  confirm,
  cancel,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  cancelAction = () => {},
  confirmAction = () => {},
}) => {
  return (
    <div className="controls">
      {cancel === true && (
        <button className="btn-modal btn-outlined-style" onClick={cancelAction}>
          {cancelText}
        </button>
      )}
      {confirm === true && (
        <button className="btn-modal btn-primary-style" onClick={confirmAction}>
          {confirmText}
        </button>
      )}
    </div>
  );
};

export const MessageModal: React.FC<MessageModalProps> = ({ show, message, children }) => {
  return (
    <>
      {show === true && (
        <div className="tree-modal-container">
          <div className="backdrop"></div>
          <div className="contentbox">
            <p className="modal-description">{message}</p>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
