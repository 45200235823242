/*
  ---< Query Fields Definition >---
*/
//  This values are the position of the variable in the query
export type graphType = 'advance' | 'lite';

export const fieldIndexesAdvanced = {
  ID_VALUES: 1,
  SOURCE_VALUES: 2,
  ABFR_VALUES: 3,
  BH_VALUES: 4,
  CONNECTIONSTATUS_VALUES: 5,
  NAT_VALUES: 6,
  UPLINKCAPACITY_VALUES: 7,
  IP_VALUES: 8,
  ASN_VALUES: 9,
  ASNAME_VALUES: 10,
  SESSION_VALUES: 11,
};

export const fieldIndexesLite = {
  ID_VALUES: 1,
  SOURCE_VALUES: 2,
  BH_VALUES: 3,
  SESSION_VALUES: 4,
};

export const timestampFieldIndex = 0;

export interface QueryFieldsAdvanced {
  idValues: string[];
  sourceValues: string[];
  abfrValues: string[];
  bufferHealthValues: string[];
  connectionStatus: string[];
  natType: string[];
  uplinkCapacity: string[];
  ip: string[];
  asn: string[];
  asname: string[];
  sessionValues: string[];
}

export interface QueryFieldsLite {
  idValues: string[];
  sourceValues: string[];
  bufferHealthValues: string[];
  sessionValues: string[];
}

export const advanceTierDataStructure = [
  {
    nodeName: 'idValues',
    fieldName: 'NodeId',
  },
  {
    nodeName: 'sourceValues',
    fieldName: 'Source',
  },
  {
    nodeName: 'abfrValues',
    fieldName: 'AggregateBufferFillingRate',
  },
  {
    nodeName: 'bufferHealthValues',
    fieldName: 'BufferHealth',
  },
  {
    nodeName: 'connectionStatus',
    fieldName: 'ConnectionStatus',
  },
  {
    nodeName: 'natType',
    fieldName: 'NatType',
  },
  {
    nodeName: 'uplinkCapacity',
    fieldName: 'UplinkCapacity',
  },
  {
    nodeName: 'ip',
    fieldName: 'Ip',
  },
  {
    nodeName: 'asn',
    fieldName: 'Asn',
  },
  {
    nodeName: 'asname',
    fieldName: 'AsName',
  },
  {
    nodeName: 'sessionValues',
    fieldName: 'SessionId',
  },
];

export const liteTierDataStructure = [
  {
    nodeName: 'idValues',
    fieldName: 'NodeId',
  },
  {
    nodeName: 'sourceValues',
    fieldName: 'Source',
  },
  {
    nodeName: 'bufferHealthValues',
    fieldName: 'BufferHealth',
  },
  {
    nodeName: 'sessionValues',
    fieldName: 'SessionId',
  },
];

export interface TierDataStructure {
  idValues: string[];
  sourceValues: string[];
  abfrValues?: string[];
  bufferHealthValues?: string[];
  connectionStatus?: string[];
  natType?: string[];
  uplinkCapacity?: string[];
  ip?: string[];
  asn?: string[];
  asname?: string[];
  sessionValues?: string[];
  levelValues?: string[];
  childrenLengthValues?: string[];
}
